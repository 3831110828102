<template>
  <div class="px-40 border border-EEEEEE py-35 purseBox">
    <!-- 标签 -->
    <div class="flex align-center justify-between pb-20 border-bottom border-F4F4F4">
      <div class="font-20 text-333333">我的明细</div>
      <div class="font-12 text-666666 flex align-center">
        目前所在：<el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/myIndex' }">个人中心</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/myPurse' }">我的钱包</el-breadcrumb-item>
          <el-breadcrumb-item>我的明细</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 内容 -->
    <div>
      <!-- 时间 -->
      <div class="px-20 py-10 purseBox_time">
        <el-date-picker
          v-model="value"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          @change="change"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
        <div class="purseBox_time_btn" @click="getdata">搜索</div>
      </div>
      <!-- 分类 -->
      <div class="flex align-center nav_ul">
        <div
          v-for="(item, index) in navList"
          :key="index"
          class="nav_li"
          :class="type == item.id ? 'nav_liact' : ''"
          @click="cuttype(item.id)"
        >
          {{ item.title }}
        </div>
      </div>
      <!-- 总收入支出 -->
      <div class="py-25 font-14 text-5E5D65 flex align-center">
        <div class="mr-65">
          收入 <span class="text-E7524C">{{ plus }}</span>
        </div>
        <div>
          支出 <span class="text-22BC80">{{ reduce }}</span>
        </div>
      </div>
      <!-- 列表 -->
      <div class="purseBox_cont">
        <el-table :data="info" style="width: 100%" height="250">
          <el-table-column prop="title" label="方式"> </el-table-column>
          <el-table-column prop="create_time" label="时间"> </el-table-column>
          <el-table-column prop="balance" label="金额">
            <template slot-scope="scope">
              <div v-if="scope.row.plus_reduce == 1" class="text-FF6253">
                +{{ scope.row.balance }}
              </div>
              <div v-else class="text-26252D">-{{ scope.row.balance }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="flex align-center justify-center py-50 border-top border-ligit-BFBFBF">
        <el-pagination
          background
          layout="prev, pager, next"
          prev-text="上一页"
          next-text="下一页"
          :page-count="page_nums"
          @current-change="currentChange"
          :current-page="page"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import req from "../../../utils/req";
export default {
  data() {
    return {
      navList: [
        {
          id: 1,
          title: "充值",
        },
        {
          id: 2,
          title: "提现",
        },
        {
          id: 3,
          title: "佣金",
        },
        {
          id: 4,
          title: "抽奖",
        },
        {
          id: 5,
          title: "消费",
        },
        {
          id: 6,
          title: "所有类型",
        },
        {
          id: 7,
          title: "消费金",
        },
        {
          id: 8,
          title: "套餐",
        },
        {
          id: 9,
          title: "创作收益",
        },
        {
          id: 10,
          title: "推荐创作人收益",
        },
        {
          id: 11,
          title: "推广收益",
        },
      ],
      type: 6,
      reduce: 0,
      plus: 0,
      info: [],
      page: 1,
      page_nums: 0,
      start_time: "",
      end_time: "",
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.page = 1;
    this.getdata();
  },
  methods: {
    // 切换类型
    cuttype(id) {
      this.type = id;
      this.page = 1;
      this.getdata();
    },
    currentChange(e) {
      console.log(e);
      this.page = e;
      this.getdata();
    },
    change() {
      console.log(this.value);
      if (this.value) {
        this.start_time = this.value[0];
        this.end_time = this.value[1];
      } else {
        this.start_time = "";
        this.end_time = "";
      }
    },
    // 获取数据
    getdata() {
      let that = this;
      req
        .post("center/myLog3", {
          type: that.type,
          start_time: that.start_time,
          end_time: that.end_time,
          page: that.page,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.info = res.data.list.data;
          that.plus = res.data.plus;
          that.reduce = res.data.reduce;
          that.page_nums = res.data.list.last_page;
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  &_ul {
    margin-top: 2.125rem;
    overflow-x: hidden;
    overflow-x: scroll;
    height: 4.5rem;
  }
  // 隐藏滚动条
  // &_ul::-webkit-scrollbar {
  //   display: none;
  // }
  &_li {
    margin-right: 3.625rem;
    cursor: pointer;
    flex-shrink: 0;
  }
  &_liact {
    flex-shrink: 0;
    font-weight: bold;
    position: relative;
  }
  &_liact::after {
    content: "";
    position: absolute;
    bottom: -30%;
    left: 0;
    width: 100%;
    height: 0.25rem;
    background: linear-gradient(90deg, #fd5653 0%, rgba(253, 86, 83, 0) 100%);
    border-radius: 0.125rem;
  }
}
.purseBox {
  height: 100%;
  box-sizing: border-box;
  &_cont {
    ::v-deep .el-table--fit {
      border-right: 1px solid #ebeef5;
      border-left: 1px solid #ebeef5;
      border-top: 1px solid #ebeef5;
    }
  }
  &_time {
    background: #f4f4f4;
    display: flex;
    align-items: center;
    &_btn {
      margin-left: 0.75rem;
      width: 4.6875rem;
      height: 2rem;
      line-height: 2rem;
      text-align: center;
      font-size: 0.75rem;
      background: #e7524c;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
</style>
